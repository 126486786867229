'use client';

import { ReactNode } from 'react';

interface StoreProvidersProps {
  providers: React.ComponentType<{ children: ReactNode }>[];
  children: ReactNode;
}

export const StoreProviders = ({ providers, children }: StoreProvidersProps) => {
  // Reduce the array of providers into a single nested component tree
  return providers.reduce((acc, Provider) => {
    return <Provider>{acc}</Provider>;
  }, children);
};
