import { Question } from 'types/collections';

import { UserAnswer } from '../lesson-page-store';

//Formats a list of questions by extracting the correct answers for each question.
export const formatQuestionsWithAnswers = (questions: Question[]) => {
  return questions.map((q) => ({
    id: q.id,
    correctAnswers: q.answers.filter((a) => a.correct).map((a) => a.answer_option),
  }));
};

//Calculates the percentage of correct answers given by a user
export const calculateCompletionScore = (
  userAnswers: UserAnswer[],
  lessonQuestions: { id: string; correctAnswers: string[] }[],
) => {
  const totalCorrectPercentage = userAnswers.reduce((totalPercentage, userAnswer) => {
    const question = lessonQuestions.find((q) => q.id === userAnswer.questionId);
    if (!question) return totalPercentage;

    const totalCorrectAnswers = question.correctAnswers.length;
    const correctMatches = userAnswer.userAnswer.filter((answer) =>
      question.correctAnswers.includes(answer),
    ).length;

    const incorrectMatches = userAnswer.userAnswer.filter(
      (answer) => !question.correctAnswers.includes(answer),
    ).length;

    const questionCorrectPercentage =
      totalCorrectAnswers > 0 && incorrectMatches === 0
        ? (correctMatches / totalCorrectAnswers) * 100
        : 0;

    return totalPercentage + questionCorrectPercentage;
  }, 0);

  const totalQuestions = lessonQuestions.length;
  return totalQuestions > 0 ? Math.round(totalCorrectPercentage / totalQuestions) : 0;
};

//Processes the user response to a question and returns the updated user answers and whether the lesson is finished
export const processUserResponse = (
  questionId: string,
  userAnswer: string[],
  currentAnswers: UserAnswer[],
  lessonQuestions: { id: string; correctAnswers: string[] }[],
) => {
  const updatedAnswers = currentAnswers.map((answer) =>
    answer.questionId === questionId ? { ...answer, userAnswer } : answer,
  );

  if (!updatedAnswers.some((answer) => answer.questionId === questionId)) {
    updatedAnswers.push({ questionId, userAnswer });
  }

  const isLessonFinished = updatedAnswers.length === lessonQuestions.length;

  return {
    updatedAnswers,
    isLessonFinished,
  };
};
