import React, { ReactNode } from 'react';

import { ErrorBoundary } from '@lib/bugsnag';

import { ErrorView } from './ErrorView';

interface ErrorHandlerProps {
  children: ReactNode;
}

export const ErrorHandler = ({ children }: ErrorHandlerProps) => {
  if (ErrorBoundary === React.Fragment) {
    return <>{children}</>;
  }

  return <ErrorBoundary FallbackComponent={ErrorView}>{children}</ErrorBoundary>;
};
