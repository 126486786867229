import React from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
  BugsnagPluginReactResult,
} from '@bugsnag/plugin-react';
import { StatusCodes } from 'http-status-codes';

import { ApiError } from './api-error';

// Initiate bugsnag
export const startBugsnag = () => {
  const apiKey = String(process.env.NEXT_PUBLIC_BUGSNAG_API_KEY);
  const appVersion = String(process.env.NEXT_PUBLIC_VERSION);
  const releaseStage = String(process.env.NEXT_PUBLIC_BUGSNAG_ENV);

  if (!Bugsnag.isStarted() && releaseStage !== 'LOCAL') {
    return Bugsnag.start({
      apiKey,
      appVersion,
      releaseStage,
      plugins: [new BugsnagPluginReact()],
      collectUserIp: false,
      onError: (event) => {
        let shouldStoreError = true;
        if (releaseStage === 'LOCAL') {
          console.error(event.originalError);
          shouldStoreError = false;
        }
        return shouldStoreError;
      },
    });
  }
};
startBugsnag();

/**
 * Notifies Bugsnag of an API error.
 *
 * @param error - The error object that was caught.
 * @param defaultMessage - An optional default message to use if the error does not contain a message.
 **/
export const notifyApiError = (error: unknown, defaultMessage?: string) => {
  // Convert error to ApiError
  let apiError = new ApiError(defaultMessage ?? 'Unknown Error', StatusCodes.INTERNAL_SERVER_ERROR);
  const directusError = (error as { errors: { message: string }[] })?.errors?.[0]?.message;
  if (directusError) {
    apiError = new ApiError(directusError, StatusCodes.INTERNAL_SERVER_ERROR);
  } else if (error instanceof Error) {
    apiError = new ApiError(error.message, StatusCodes.INTERNAL_SERVER_ERROR);
  } else if (error instanceof ApiError) {
    apiError = error;
  } else {
    // Output unknown error to console
    console.error(defaultMessage, error);
  }
  // Output ApiError to console
  console.error(defaultMessage, apiError);

  // Notify to Bugsnag
  Bugsnag.notify(apiError);

  return apiError;
};

// Create boundary
let ErrorBoundary: BugsnagErrorBoundary = React.Fragment;
let BugsnagReact: BugsnagPluginReactResult | undefined;
if (typeof window !== 'undefined') BugsnagReact = Bugsnag.getPlugin('react');
if (BugsnagReact) {
  ErrorBoundary = BugsnagReact.createErrorBoundary(React);
}
export { ErrorBoundary };
