import styled from 'styled-components';

import { breakpoints } from '@styles/breakpoint';
import { Spacers } from '@styles/theme';

export const Description = styled.div<{ $isPageIntro?: boolean }>`
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.typographyBodyLight};
  font-weight: ${(props) => props.theme.typography.body.fontWeight};
  line-height: ${(props) => props.theme.typography.body.lineHeight};

  > * + * {
    margin-top: ${Spacers.BASE}rem;
  }

  h3 + * {
    margin-top: ${Spacers.XS}rem;
  }

  &.page-intro {
    ${breakpoints.desktopOrBigger} {
      grid-column: 2 / 7;
    }
  }
`;
