import { useSession } from 'next-auth/react';

import Bugsnag from '@bugsnag/js';

/**
 * This component just sets the user for the bugsnag error handler
 */
export const UserErrorHandler = () => {
  const session = useSession();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const user = session?.data?.user as any;
  if (user) {
    Bugsnag.setUser(user.id);
  }

  return null;
};
