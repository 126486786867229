import React, { useEffect } from 'react';

interface UseAxeOptions {
  timeout?: number;
  config?: object;
}

/**
 * Custom hook to integrate axe-core accessibility testing in a Next.js application.
 *
 * @param {Object} options - Configuration options for axe-core
 * @param {number} [options.timeout=1000] - Delay in milliseconds before first automatic scan
 * @param {Object} [options.config={}] - Additional configuration options for axe-core
 */
export function useAxe({ timeout = 1000, config = {} }: UseAxeOptions = {}): void {
  useEffect(() => {
    const isServerSideRendered = typeof window === 'undefined';
    const isProductionEnv = process.env.NODE_ENV === 'production';

    if (!isProductionEnv && !isServerSideRendered) {
      import('react-dom').then((ReactDOM) => {
        import('@axe-core/react').then((axe) => {
          axe.default(React, ReactDOM, timeout, config);
        });
      });
    }
  }, [timeout, config]);
}
