import Image from 'next/image';

import styled from 'styled-components';

import { breakpoints } from '@styles/breakpoint';
import { Spacers } from '@styles/theme';

export const StateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;
  justify-content: center;
  height: 100%;
  gap: ${Spacers.XXL}rem;
`;

export const IllustrationWrapper = styled.div`
  display: flex;
  align-self: center;
  position: relative;
  width: 16.25rem;
  height: 16.25rem;
  max-width: 100%;

  ${breakpoints.desktopOrBigger} {
    width: 22.5rem;
    height: 22.5rem;
  }
`;

export const Illustration = styled(Image)`
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacers.XS}rem;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacers.BASE}rem;
  position: fixed;
  bottom: ${Spacers.XXL}rem;
  left: 0;
  right: 0;

  ${breakpoints.desktopOrBigger} {
    position: unset;
  }
`;
