import styled from 'styled-components';

import { breakpoints } from '@styles/breakpoint';
import { Spacers } from '@styles/theme';

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.heading2.fontSize};
  color: ${({ theme }) => theme.colors.typographyHeadersLight};
  font-weight: ${(props) => props.theme.typography.heading2.fontWeight};
  line-height: ${(props) => props.theme.typography.heading2.lineHeight};
`;

export const TitleContainer = styled.div`
  padding-block: ${Spacers.LG}rem;
  ${breakpoints.desktopOrBigger} {
    grid-column: 2 / -2;
  }
`;
