'use client';

import { type ReactNode, useRef } from 'react';

import { createStore, type StoreApi } from 'zustand';

interface ContentVisibilityStore {
  isVisible: boolean;
  setVisibility: (visible: boolean) => void;
}

const createContentVisibilityStore = () =>
  createStore<ContentVisibilityStore>((set) => ({
    isVisible: true,
    setVisibility: (visible) => set({ isVisible: visible }),
  }));

export function ContentVisibilityStoreProvider({ children }: { children: ReactNode }) {
  const storeRef = useRef<StoreApi<ContentVisibilityStore>>();

  if (!storeRef.current) {
    storeRef.current = createContentVisibilityStore();
  }

  return children;
}
