import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Work_Sans\",\"arguments\":[{\"weight\":[\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-work-sans\"}],\"variableName\":\"workSans\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/assets/fonts/FSMeWeb-Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/FSMeWeb-Bold.woff\",\"weight\":\"500\",\"style\":\"normal\"}],\"variable\":\"--font-fsme\"}],\"variableName\":\"fsme\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ClientProviders"] */ "/workspace/src/components/ClientProviders/ClientProviders.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Layouts/GridLayout/GridLayout.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/TranslationsProvider/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/lib/registry.tsx");
