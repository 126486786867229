'use client';
import { ReactNode } from 'react';

import { ThemeProvider } from 'styled-components';

import { ClassPageStoreProvider } from '@providers/class-page-provider';
import { LessonStoreProvider } from '@providers/lesson-page-provider';
import { StoreProviders } from '@providers/StoreProviders';
import { ContentVisibilityStoreProvider } from '@providers/use-content-visibility-provider';
import { GlobalStyles, lightTheme } from '@styles/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAxe } from 'hooks/useAxe';
import { Resource } from 'i18next';

import { ErrorHandler } from '@components/Error';
import { TranslationsProvider } from '@components/TranslationsProvider';

export interface ClientProvidersProps {
  locale: string;
  children: ReactNode;
  resources: Resource;
  namespaces: string[];
}

export const ClientProviders = ({
  children,
  locale,
  resources,
  namespaces,
}: ClientProvidersProps) => {
  const queryClient = new QueryClient();
  useAxe();

  return (
    <ErrorHandler>
      <TranslationsProvider locale={locale} resources={resources} namespaces={namespaces}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={lightTheme}>
            <StoreProviders
              providers={[
                ClassPageStoreProvider,
                LessonStoreProvider,
                ContentVisibilityStoreProvider,
              ]}>
              <GlobalStyles />
              {children}
            </StoreProviders>
          </ThemeProvider>
        </QueryClientProvider>
      </TranslationsProvider>
    </ErrorHandler>
  );
};
