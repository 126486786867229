'use client';

import { createContext, type ReactNode, useContext, useRef } from 'react';

import { ClassPageStore, createClassPageStore } from '@stores/class-page-store';
import { useStore } from 'zustand';

// Create the initial state just once, outside of the component
const initialState = {
  lessons: [],
  completedLessons: [],
};

export type ClassPageApi = ReturnType<typeof createClassPageStore>;

export const ClassPageStoreContext = createContext<ClassPageApi | undefined>(undefined);

export interface ClassPageStoreProviderProps {
  children: ReactNode;
}

export const ClassPageStoreProvider = ({ children }: ClassPageStoreProviderProps) => {
  const storeRef = useRef<ClassPageApi>();
  if (!storeRef.current) {
    // Pass the pre-created initialState instead of calling a function
    storeRef.current = createClassPageStore(initialState);
  }

  return (
    <ClassPageStoreContext.Provider value={storeRef.current}>
      {children}
    </ClassPageStoreContext.Provider>
  );
};

export const useClassPageStore = <T,>(selector: (store: ClassPageStore) => T): T => {
  const classPageStoreContext = useContext(ClassPageStoreContext);

  if (!classPageStoreContext) {
    throw new Error('useClassPageStore must be used within ClassPageStoreProvider');
  }

  // Add a simple equality function to prevent unnecessary re-renders
  return useStore(classPageStoreContext, selector);
};
