import { Question } from 'types/collections';
import { create } from 'zustand';

import {
  calculateCompletionScore,
  formatQuestionsWithAnswers,
  processUserResponse,
} from './utils/lessonPageCalculations';

export type UserAnswer = {
  questionId: string;
  userAnswer: string[];
};

export type LessonState = {
  isLessonFinished: boolean;
  isCompleteScreenVisible: boolean;
  lessonQuestions: { id: string; correctAnswers: string[] }[];
  userAnswers: UserAnswer[];
  completionScore: number;
};

export type LessonActions = {
  setLessonQuestions: (questions: Question[]) => void;
  recordUserResponse: (questionId: string, userAnswer: string[]) => void;
  calculateProgress: () => void;
  resetLesson: () => void;
  setIsCompleteScreenVisible: (isCompleteScreenVisible: boolean) => void;
};

const initialState: LessonState = {
  isLessonFinished: false,
  isCompleteScreenVisible: false,
  lessonQuestions: [],
  userAnswers: [],
  completionScore: 0,
};

export const useLessonStore = create<LessonState & LessonActions>((set, get) => ({
  ...initialState,

  setLessonQuestions: (questions: Question[]) => {
    const formattedQuestions = formatQuestionsWithAnswers(questions);
    const hasNoQuestions = formattedQuestions.length === 0;

    set({
      lessonQuestions: formattedQuestions,
      userAnswers: [],
      completionScore: 0,
      isLessonFinished: hasNoQuestions,
      isCompleteScreenVisible: false,
    });
  },

  recordUserResponse: (questionId, userAnswer) => {
    const { userAnswers, lessonQuestions } = get();

    const { updatedAnswers, isLessonFinished } = processUserResponse(
      questionId,
      userAnswer,
      userAnswers,
      lessonQuestions,
    );

    set({ userAnswers: updatedAnswers, isLessonFinished });

    if (isLessonFinished) {
      get().calculateProgress();
    }
  },

  calculateProgress: () => {
    const { userAnswers, lessonQuestions } = get();
    const completionScore = calculateCompletionScore(userAnswers, lessonQuestions);
    set({ completionScore });
  },

  setIsCompleteScreenVisible: (isCompleteScreenVisible) => set({ isCompleteScreenVisible }),

  resetLesson: () => {
    const { lessonQuestions } = get();
    const hasNoQuestions = lessonQuestions.length === 0;

    set({
      ...initialState,
      lessonQuestions,
      isLessonFinished: hasNoQuestions,
    });
  },
}));
