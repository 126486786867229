import { usePathname, useRouter } from 'next/navigation';

// Navigation hook
export const useNavigate = () => {
  const router = useRouter();
  const pathname = usePathname();

  return {
    goBack: () => {
      const pathSegments = pathname?.split('/').filter(Boolean);

      if (!pathSegments || pathSegments.length <= 2) {
        router.back();
        return;
      }

      router.push(`/${pathSegments.slice(0, -2).join('/')}`);
    },

    goToPath: (path: string) => {
      router.push(path);
    },

    goToRoute: (route: string, params?: Record<string, string>) => {
      if (!params) {
        router.push(route);
        return;
      }

      const queryString = new URLSearchParams(params).toString();
      router.push(`${route}?${queryString}`);
    },

    refresh: () => {
      router.refresh();
    },

    hardRefresh: () => {
      window.location.reload();
    },

    replace: (path: string) => {
      router.replace(path);
    },

    currentPath: pathname,
  };
};
