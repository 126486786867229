'use client';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'hooks/useNavigation';

import { Button } from '@components/Button';
import { Description, Title } from '@components/Common';

import {
  ButtonContainer,
  Illustration,
  IllustrationWrapper,
  StateWrapper,
  TextContent,
} from './styles';

/**
 * Error View component displays when there are an errors that occur in GRIP
 * and are effecting the front-end
 */
export const ErrorView = () => {
  const { goBack } = useNavigate();
  const { t } = useTranslation('common');

  const resetPage = () => {
    window.location.reload();
  };

  return (
    <StateWrapper className="full-height">
      <IllustrationWrapper>
        <Illustration
          priority
          fill
          src={'/assets/images/error_illustration.svg'}
          alt={t('errors.errorStateImageText')}
        />
      </IllustrationWrapper>
      <TextContent>
        <Title>{t('errors.errorStateTitle')}</Title>
        <Description>{t('errors.errorStateDescription')}</Description>
      </TextContent>
      <ButtonContainer>
        <Button type="submit" label={t('errors.reload')} variant={'primary'} onClick={resetPage} />
        <Button
          type="submit"
          label={t('errors.returnToPreviousPage')}
          variant={'secondary'}
          onClick={goBack}
        />
      </ButtonContainer>
    </StateWrapper>
  );
};
