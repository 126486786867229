import { ClassLesson } from 'types/collections';
import { createStore } from 'zustand/vanilla';

export type ClassPageState = {
  lessons: ClassLesson[];
  completedLessons: string[];
};

export type ClassPageActions = {
  setLessons: (lessons: ClassLesson[]) => void;
  setCompletedLessons: (lessonIds: string[]) => void;
  reset: () => void;
};

export type ClassPageStore = ClassPageState & ClassPageActions;

export const createClassPageStore = (initState: ClassPageState) => {
  return createStore<ClassPageStore>()((set) => ({
    ...initState,
    setLessons: (lessons) => set({ lessons }),
    setCompletedLessons: (lessonIds) => set({ completedLessons: lessonIds }),
    reset: () => set({ completedLessons: [] }),
  }));
};
