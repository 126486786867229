import styled from 'styled-components';

import { breakpoints } from '@styles/breakpoint';
import { Spacers } from '@styles/theme';

export const ButtonsContainer = styled.div`
  display: grid;
  gap: ${Spacers.BASE}rem;
  padding-top: ${Spacers.BASE}rem;
  grid-column: 1 / -1;

  ${breakpoints.desktopOrBigger} {
    grid-column: 2 / -2;
  }
`;
