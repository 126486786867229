'use client';

import { createContext, PropsWithChildren, useContext, useRef } from 'react';

import { useLessonStore } from '@stores/lesson-page-store';
import { StoreApi, useStore } from 'zustand';

const LessonStoreContext = createContext<StoreApi<ReturnType<typeof useLessonStore>> | null>(null);

export function LessonStoreProvider({ children }: PropsWithChildren) {
  const storeRef = useRef<StoreApi<ReturnType<typeof useLessonStore>>>();

  if (!storeRef.current) {
    storeRef.current = useLessonStore;
  }
  return (
    <LessonStoreContext.Provider value={storeRef.current}>{children}</LessonStoreContext.Provider>
  );
}

export function useLessonStoreContext<T>(
  selector: (state: ReturnType<typeof useLessonStore>) => T,
): T {
  const store = useContext(LessonStoreContext);
  if (!store) {
    throw new Error('Make sure to wrap your component tree with the provider');
  }

  return useStore(store, selector);
}
